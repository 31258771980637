import $ from 'jquery'
const $doc = $(document)

function scrollToTop(){
    window.scrollTo(0, 0);
}

export default function initScrollToTop(){
    $doc.on('click', '.js-scrollup', scrollToTop)
}
